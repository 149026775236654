import { useState, useRef, useEffect, useCallback } from 'react';
import RegistrationForm from '../RegistrationForm.js';
import FileUpload, { uploadFileInChunks } from 'components/FileUpload.js';
import { useLoading } from 'components/LoadingProvider.js';
import { ALLOWED_MODEL_FILE_EXTENSIONS, RAW_MODEL_FILE_EXTENSIONS, ONE_HUNDRED_MB_IN_BYTES } from 'shared/Constants.js';
import Accordion from 'react-bootstrap/Accordion';
import HorizontalScroll from 'components/HorizontalScroll.js';
import RollingOverflowText from 'components/RollingOverflowText.js';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SwitchButton from 'components/SwitchButton.js';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Collapse from 'react-bootstrap/Collapse';
import MediaModal from 'components/MediaModal.js';
import JSZip from "jszip";
import { getFileExtension } from 'shared/Helpers/Utils.js';
import FloorSeparator from 'components/FloorSeparator.js';

const DEMO_MODELS = [
    { Title: "Demo Factory", Key: "demo_factory", ThumbnailExtension: "jpg" },
    { Title: "Demo Office", Key: "demo_office", ThumbnailExtension: "jpg" },
    { Title: "Demo Hospital", Key: "demo_hospital", ThumbnailExtension: "jpg" },
    { Title: "Demo City", Key: "demo_city", ThumbnailExtension: "jpg" },
    { Title: "Demo Airport", Key: "demo_airport", ThumbnailExtension: "jpeg" },
    { Title: "Demo Stadium", Key: "demo_stadium", ThumbnailExtension: "jpeg" },
    { Title: "Demo Construction", Key: "demo_construction", ThumbnailExtension: "jpeg" }
];

const DemoModelCard = ({selectedDemoModel, demoModel, selectDemoModelCard}) => {
    const cardRef = useRef();
    return (                                    
    <div className={`card${selectedDemoModel === demoModel ? " selected" : ""}`} onClick={() => {selectDemoModelCard(demoModel);}} id={demoModel.Title.replace(/\s/g, '') + "Model"} ref={cardRef}>
        <img src={`/${demoModel.Key}.${demoModel.ThumbnailExtension}`} className="card-img-top" alt={demoModel.Title}/>
        <div className="card-body">
            <h6 className="card-title"><RollingOverflowText text={demoModel.Title} parentRef={cardRef}></RollingOverflowText></h6>
        </div>
    </div>
    );
}

const fileUploadValidationOptions = [
    {validate: (files) => {
        let valid = true;
        const filesArray = Array.from(files);
        if (files.length > 1 && filesArray.map((file) => getFileExtension(file)).some((extension) => extension === "zip")) // do not allow individual files AND zip at the same time
            valid = false;

        return valid;
    }, errorMessage: "Please upload individual files only or .ZIP only."},
    {validate: (files) => {
        let valid = true;
        const maxFilesSize = ONE_HUNDRED_MB_IN_BYTES * 2;
        const filesArray = Array.from(files);
        const totalSize = filesArray.reduce((accumulator, file) => accumulator + file.size, 0);

        if (totalSize > maxFilesSize)
            valid = false;

        return valid;
    }, errorMessage: "You exceeded the maximum size limit of 200 MB."},
    {validate: async (files) => {
        let valid = true;
        const maximumFileCount = 100;
        let fileCount = 0;

        if (getFileExtension(files[0]) !== "zip") {
            const filesArray = Array.from(files);
            fileCount = filesArray.reduce((accumulator, _file) => accumulator + 1, 0);
        } else {
            try {
                const arrayBuffer = await files[0].arrayBuffer();
                const zip = await JSZip.loadAsync(arrayBuffer);
                // Loop through each file entry in the zip
                Object.values(zip.files).forEach(file => {
                    if (!file.dir) {
                        // If the file is not a directory, increase the file count (that is because dir in dir is a file in the zip)
                        fileCount += 1;
                    }
                });
            } catch (_) {
                valid = false;

                return valid;
            }
        }

        if (fileCount > maximumFileCount)
            valid = false;

        return valid;
    }, errorMessage: "Maximum file count is 100."},
    {validate: async (files) => {
        let valid = true;

        if (getFileExtension(files[0]) === "zip") {
            try {
                const arrayBuffer = await files[0].arrayBuffer();
                const zip = await JSZip.loadAsync(arrayBuffer);
                const zippedFileExtensions = Object.values(zip.files).map((file) => getFileExtension(file));
                valid = !zippedFileExtensions.some(extension => extension === "zip");
            } catch (_) {
                valid = false;
            }
        }

        return valid;
    }, errorMessage: "The uploaded .ZIP archive can not contain other .ZIP archives."},
    {validate: (files) => {
        let valid = true;
        const filesArray = Array.from(files);
        const fileExtensions = filesArray.map((file) => getFileExtension(file));

        if (getFileExtension(files[0]) !== "zip") {
            const modelFiles = fileExtensions.filter((extension) => 
                RAW_MODEL_FILE_EXTENSIONS.some((rawModelExtension) => extension === rawModelExtension));
            valid = modelFiles.length === 1;
        }

        return valid;
    }, errorMessage: `The files must contain exactly one file with extension ${RAW_MODEL_FILE_EXTENSIONS.map((extension) => `.${extension.toUpperCase()}`).join(" or ")}.`},
    {validate: async (files) => {
        let valid = true;

        if (files.length === 1 && getFileExtension(files[0]) === "zip") {
            try {
                const arrayBuffer = await files[0].arrayBuffer();
                const zip = await JSZip.loadAsync(arrayBuffer);
                const fileExtensions = Object.values(zip.files).map((file) => getFileExtension(file));
                const modelFiles = fileExtensions.filter((extension) => 
                    RAW_MODEL_FILE_EXTENSIONS.some((rawModelExtension) => extension === rawModelExtension));
                valid = modelFiles.length === 1;
            } catch (_) {
                valid = false;
            }
        }

        return valid;
    }, errorMessage: `The .ZIP file must contain exactly one file with extension ${RAW_MODEL_FILE_EXTENSIONS.map((extension) => `.${extension.toUpperCase()}`).join(" or ")}.`},
    {validate: async (files) => {
        let valid = true;

        let fileExtensions;
        if (getFileExtension(files[0]) !== "zip") {
            const filesArray = Array.from(files);
            fileExtensions = filesArray.map((file) => getFileExtension(file));
        } else {
            try {
                const arrayBuffer = await files[0].arrayBuffer();
                const zip = await JSZip.loadAsync(arrayBuffer);
                fileExtensions = Object.values(zip.files).map((file) => getFileExtension(file));
            } catch (_) {
                valid = false;

                return valid;
            }
        }

        const mtlFiles = fileExtensions.filter((extension) => 
            extension === "mtl");
        const isModelFileObj = fileExtensions.some(extension => extension === "obj")
        if (isModelFileObj && mtlFiles.length > 1) {
            valid = false;
        } else if (!isModelFileObj && mtlFiles.length > 0) {
            valid = false;
        }

        return valid;
    }, errorMessage: `Only model format .OBJ can have exactly one .MTL file.`}
];

const RegistrationModelUploadForm = ({ onNext, onCancel, showPrevious, showNext, showSkip, nextButtonText }) => {
    const { setLoading, setTextHeader, setTextMessage, setProgress } = useLoading();
    const [files, setFiles] = useState([]);
    const [isFileValid, setIsFileValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isInputValid, setIsInputValid] = useState(false);
    const [isFloorSeparatorValid, setIsFloorSeparatorValid] = useState(false);
    const inputFileElementRef = useRef();
    const [demoModel, setDemoModel] = useState(null);
    const horizontalMenuRef = useRef();
    const [addvancedSettingsActiveKey, setAddvancedSettingsActiveKey] = useState(null);
    const [hasModelHierarchy, setHasModelHierarchy] = useState(false);
    const [floorSeparations, setFloorSeparations] = useState([]);
    const [videoModalVisible, setVideoModalVisible] = useState(false);
    const floorSeparationAnimationVideoRef = useRef(null);

    useEffect(() => {
        setIsInputValid(isFileValid && (addvancedSettingsActiveKey === "0" && !hasModelHierarchy ? isFloorSeparatorValid : true));
    }, [isFileValid, addvancedSettingsActiveKey, isFloorSeparatorValid, hasModelHierarchy]);

    const handleNext = async () => {
        if (isInputValid) {
            setTextHeader("Uploading model...")
            setProgress(0);
            setLoading(true);

            if (files.length) {
                async function uploadZip(zipArchiveContent, modelFileExtension) {
                    setTextMessage("Sending your model. Please wait...");
                    const modelJobGuid = crypto.randomUUID();
                    const url = `/Registration/model/${modelJobGuid}/${modelFileExtension}`;
                    uploadFileInChunks(zipArchiveContent, url, (fileUploadProgress) => {
                        setProgress(fileUploadProgress);
            
                        if (fileUploadProgress === 100) {
                            setTextHeader("Processing model...")
                            setTextMessage("Almost done. Please wait...");
                            setProgress(null);
                        }   
                    }, () => {
                        // All chunks uploaded, notify success
                        setProgress(null);
                        setLoading(false);
    
                        // Move to the next step of onboarding
                        onNext();
                    }, () => {
                        setProgress(null);
                        setLoading(false);
                    });
                }

                let zip;
                if (getFileExtension(files[0]) === "zip") {
                    const arrayBuffer = await files[0].arrayBuffer();
                    zip = await JSZip.loadAsync(arrayBuffer);
                } else {
                    setTextMessage("Zipping your model. Please wait...");
                    zip = new JSZip();
    
                    Array.from(files).forEach(file => {
                        zip.file(file.name, file);
                    });
                }
                
                const fileExtensions = Object.values(zip.files).map((file) => getFileExtension(file));
                const modelFileExtension = fileExtensions.filter((extension) => 
                    RAW_MODEL_FILE_EXTENSIONS.some((rawModelExtension) => extension === rawModelExtension))[0];

                if (addvancedSettingsActiveKey === "0" && !hasModelHierarchy)
                    zip.file("floorSeparations.json", JSON.stringify(floorSeparations));

                zip.generateAsync({ type: 'blob',
                    compression: "DEFLATE", /* deflate is the name of the compression algorithm used */
                    compressionOptions: { /* compression level ranges from 1 (best speed) to 9 (best compression) */
                        level: 4
                    }
                 }).then(function(content) {
                    uploadZip(content, modelFileExtension);
                });
            } else {
                const modelJobGuid = crypto.randomUUID();
                fetch(`/Registration/model/${modelJobGuid}`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(demoModel.Key),
                  })
                    .then((response) => {
                      if (response.ok) {
                        onNext();
                      } else {
                        setErrorMessage("Setting demo model failed.");
                      }
                    })
                    .catch((error) => {
                      setErrorMessage("Something went wrong.");
                      console.error(error);
                    })
                    .finally(() => {
                      setLoading(false);
                    });
            }
        }
    };

    const selectDemoModel = useCallback((newDemoModel) => {
        if (newDemoModel === demoModel) { // if click on already selected demo model, deselect
            setDemoModel(null);
            inputFileElementRef.current.setTitle(null);

            document.getElementById("DropDownAdvancedModelSettings").querySelector("button").removeAttribute("disabled"); // disable advancedSettings if deselect a demoModel
        } else {
            setDemoModel(newDemoModel); 
            inputFileElementRef.current.setTitle(newDemoModel?.Title);

            setAddvancedSettingsActiveKey(null); // close advanced settings
            if (newDemoModel)
                document.getElementById("DropDownAdvancedModelSettings").querySelector("button").setAttribute("disabled", "");
            else
                document.getElementById("DropDownAdvancedModelSettings").querySelector("button").toggleAttribute("disabled");
        }
    }, [demoModel]);

    useEffect(() => {
        if (files.length)
            selectDemoModel(null);
    }, [files, selectDemoModel]);

    return (
        <RegistrationForm
            onNext={handleNext}
            onSkip={onNext}
            onCancel={onCancel}
            isInputValid={isInputValid}
            showPrevious={showPrevious}
            showNext={showNext}
            showSkip={showSkip}
            nextButtonText={nextButtonText}
        >
            <div className="registrationModelUploadForm">
                <h4>Upload the model</h4>
                <div className="dialogContent">
                    <p>
                        Upload your 3D model in either <b>.FBX</b> or <b>.OBJ</b> format including textures (supported <b>.JPG</b>, <b>.PNG</b> and <b>.MTL</b> formats). The maximum file size together is <b>200 MB</b> and the maximum number of files is <b>100</b>. Import the files separately or zipped.
                    </p>
                </div>

                <div className='p-3'>
                    <p>Model Files <span className="infoText">(.OBJ, .FBX, .JPG, .PNG, .MTL or .ZIP)</span></p>
                    <FileUpload ref={inputFileElementRef} className="mt-2" placeholder="Select Model File..." multiple required={!!!demoModel}
                    files={files} setFiles={setFiles} valid={isFileValid} setValid={setIsFileValid}
                    validationOptions={fileUploadValidationOptions}
                    allowedExtensions={ALLOWED_MODEL_FILE_EXTENSIONS}/>
                    {errorMessage && <p className={"error-message mt-3"}>{errorMessage}</p>}
                </div>

                <div className='uploadModelDropdownSection'>
                    <Accordion flush activeKey={addvancedSettingsActiveKey} onSelect={(newKey) => {
                        if (newKey === "0")
                            floorSeparationAnimationVideoRef.current.currentTime = 0;
                        setAddvancedSettingsActiveKey(newKey);
                    }}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header id="DropDownAdvancedModelSettings">{"Advanced Settings"}</Accordion.Header>
                            <Accordion.Body className='AdvancedSettings'>
                                <h4 className='p-3'>Model Type
                                            <OverlayTrigger overlay={<Tooltip id="DropDownAdvancedModelSettingsModelTypeInfoTooltip" className="tooltip">
                                            Documentation
                                                </Tooltip>} delay={500} container={document.getElementById("DropDownAdvancedModelSettingsModelTypeInfo")}>
                                                <a id="DropDownAdvancedModelSettingsModelTypeInfo" className='iconLink leading-trim-material-icon d-inline-block'
                                                    href='https://twinzo.atlassian.net/wiki/spaces/PUBD/pages/269221904/How+to+prepare+multi-story+3D+building+for+Twinzo+app#Manual-way'
                                                    target="_blank" rel="noreferrer">
                                                        <InfoOutlinedIcon className='mx-3'/>
                                                </a>
                                            </OverlayTrigger>
                                </h4>
                                <div className='d-flex justify-content-between align-items-center p-3'>
                                    <h6 className=''>Model has Hierarchy</h6>
                                    <SwitchButton value={hasModelHierarchy} setValue={(value) => {
                                        if (!value && hasModelHierarchy) // if opening up the collapse
                                            floorSeparationAnimationVideoRef.current.currentTime = 0;
                                        setHasModelHierarchy(value);
                                    }} id="DropDownAdvancedModelSettingsModelHasHierarchy"
                                    aria-controls="FloorSeparationCollapsible"
                                    />
                                </div>
                                <Collapse in={!hasModelHierarchy}>
                                    <div id="FloorSeparationCollapsible">
                                        <h4 className='p-3'>Floor Separation
                                            <OverlayTrigger overlay={<Tooltip id="DropDownAdvancedModelSettingsFloorSeparationInfoTooltip" className="tooltip">
                                            Documentation
                                                </Tooltip>} delay={500} container={document.getElementById("DropDownAdvancedModelSettingsFloorSeparationInfo")}>
                                                <a id="DropDownAdvancedModelSettingsFloorSeparationInfo" className='iconLink leading-trim-material-icon d-inline-block'
                                                    href='https://twinzo.atlassian.net/wiki/spaces/PUBD/pages/269221904/How+to+prepare+multi-story+3D+building+for+Twinzo+app#Automatic-way-(coming-soon!)'
                                                    target="_blank" rel="noreferrer">
                                                        <InfoOutlinedIcon className='mx-3'/>
                                                </a>
                                            </OverlayTrigger>
                                        </h4>
                                        <p className='p-3'>
                                            Set the <b>number of floors</b> and <b>Cutting planes for floors</b> by entering the height of each floor from the lowest floor to the ceiling of that floor (where the model should be cut). The unit is in meters.
                                        </p>
                                        <MediaModal visible={videoModalVisible} onHide={
                                            () => {
                                                floorSeparationAnimationVideoRef.current.play();
                                                setVideoModalVisible(false);
                                            }
                                        }>
                                            <video src="/Floorcuttinganim_1024.mp4" autoPlay loop controls></video>
                                        </MediaModal>
                                        <div className="videoContainer" onClick={() => {
                                                floorSeparationAnimationVideoRef.current.pause();
                                                setVideoModalVisible(prev => !prev); 
                                            }}>
                                            <video src="/Floorcuttinganim_1024.mp4" id="DropDownAdvancedModelSettingsAnimations" 
                                            autoPlay loop ref={floorSeparationAnimationVideoRef}></video>
                                        </div>
                                        <FloorSeparator floorSeparations={floorSeparations} setFloorSeparations={setFloorSeparations} valid={isFloorSeparatorValid}
                                        setValid={setIsFloorSeparatorValid} isVisible={!hasModelHierarchy && addvancedSettingsActiveKey === "0"}/>
                                    </div>
                                </Collapse>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <h4 className='py-4'>Don't have a 3D model?</h4>
                    <div>
                    <Accordion flush alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header id="DropdownDemoModels">{"Try some of our Demo models:"}</Accordion.Header>
                            <Accordion.Body>
                                <HorizontalScroll horizontalScrollContainerRef={horizontalMenuRef}>
                                    <div className='horizontalMenu horizontalScroll' ref={horizontalMenuRef}>  
                                        {DEMO_MODELS.map(model => {
                                            return (
                                                <DemoModelCard selectedDemoModel={demoModel} demoModel={model} selectDemoModelCard={selectDemoModel} key={model.Key}/>
                                            )
                                        })}
                                    </div>
                                </HorizontalScroll>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header id="DropdownSuggestions">{"Here are some suggestions to help you get started:"}</Accordion.Header>
                            <Accordion.Body>
                            <div className="further-info-field">
                                <ul className="links-list">
                                    <li><a href="https://www.twinzo.eu/blog/blog-1/tutorial-scan-your-model-with-iphone-59" target="_blank" rel="noreferrer" id="RedirectLink1">Scan a physical object with a 3D scanning app on your smartphone and export it in .fbx or .obj format.</a></li>
                                    <li><a href="https://www.twinzo.eu/blog/blog-1/navigating-the-world-of-3d-modeling-55" target="_blank" rel="noreferrer" id="RedirectLink2">Create a model using your preferred 3D modeling solution and export it in .fbx or .obj format.</a></li>
                                    <li><a href="https://www.visualcomponents.com/" target="_blank" rel="noreferrer" id="RedirectLink3">Use Visual Components to build and export your model.</a></li>
                                    <li><a href="https://www.twinzo.eu/partners" target="_blank" rel="noreferrer" id="RedirectLink4">Hire a freelance 3D modeler to create a model for you. Check our partners.</a></li>
                                    <li><a href="https://twinzo.atlassian.net/wiki/spaces/PUBD/pages/109740040" target="_blank" rel="noreferrer" id="RedirectLink5">Purchase a pre-made model from an online 3D model marketplace.</a></li>
                                    <li><a href="https://www.twinzo.eu/blog/blog-1/how-to-scan-your-facility-54" target="_blank" rel="noreferrer" id="RedirectLink6">Create a 3D model from photographs using photogrammetry software.</a></li>
                                </ul>
                            </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    </div>
                </div>
            </div>
        </RegistrationForm>
    );
};

export default RegistrationModelUploadForm;
