// This code sets up the Bootstrap CSS framework.
//import 'bootstrap/dist/css/bootstrap.min.css'; - no need to import this since twinzo-theme.scss imports bootstrap
//import 'bootstrap/dist/js/bootstrap.bundle.min'; - React bootstrap is used, so no need to import regular bootstrap js

// This code is used to load the Twinzo theme styles.
import "./content/themes/twinzo-theme.scss";
import { calculateAndSetBrowserScrollbarSizeToRootCssVariable } from "./shared/Helpers/Utils";

// Setup React and the application.
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

calculateAndSetBrowserScrollbarSizeToRootCssVariable();

root.render(
  <BrowserRouter basename="/">
    <App />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
