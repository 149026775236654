import React, { useState, useEffect } from "react";
import RegistrationForm from "../RegistrationForm.js";
import { useLoading } from "components/LoadingProvider.js";
import FloatedInput from "components/FloatedInput.js";
import ModelState from "shared/Helpers/ModelState.js";

const clientNameValidationOptions = [
  { pattern: /^[a-zA-Z0-9]{3,50}$/, errorMessage: "Name of the company/project must consist of only letters and numbers, with length between 3 and 50." }
];

const RegistrationClientForm = ({ onNext, onCancel, clientName, setClientName, showCancel, showPrevious, showNext, showSkip, nextButtonText }) => {
  const { setLoading, setTextHeader, setTextMessage, setProgress } = useLoading();
  const [errorMessage, setErrorMessage] = useState("");
  const [isInputValid, setIsInputValid] = useState(false);

  useEffect(() => {
    setErrorMessage("");
  }, [clientName]);

  const handleNext = async (e) => {
    // set loading text
    setTextHeader("Checking if company/project exists...");
    setTextMessage("twinzo is looking...");

    setLoading(true);
    setProgress(0);
    // check if client already exists via Registration controller
    try {
      const response = await fetch(`/Registration/client/exists/${clientName}`);
      if (response.ok) {
        const data = await response.json();
        if (data) {
          // Client already exists with the given login
          ModelState.validate({"clientName": ["This name is already used, please choose a different one"]})
        } else {
          // Client name is unique and valid, so we can proceed to the next form step
          setErrorMessage(null);
          onNext();
        }
      } else {
        setErrorMessage("Something went wrong.");
      }
    } catch (error) {
      setErrorMessage("Something went wrong.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <RegistrationForm
      onNext={handleNext}
      onSkip={onNext}
      onCancel={onCancel}
      isInputValid={isInputValid}
      showCancel={showCancel}
      showPrevious={showPrevious}
      showNext={showNext}
      showSkip={showSkip}
      nextButtonText={nextButtonText}
    >
      <>
        <h4>Type in the name of your company/project or other</h4>
        <div className="dialogContent">
          <p>
            To begin, please provide a name for your account, organization,
            project, group, or domain. The name should be no longer than 50
            characters and cannot include any special characters.
          </p>
          <div className="form-group">
            {/* Client Login name input */}
            <FloatedInput type="text" id="name-input" className="mt-3" required value={clientName} setValue={setClientName} valid={isInputValid} setValid={setIsInputValid}
            validationOptions={clientNameValidationOptions} name="clientName"
            label="Name of the company/project..."/>
          </div>
          {errorMessage && <p className={"error-message mt-3"}>{errorMessage}</p>}
        </div>
      </>
    </RegistrationForm>
  );
};

export default RegistrationClientForm;
