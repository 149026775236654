import { useCallback, useEffect, useState, memo } from "react";
import RollingOverflowText from "./RollingOverflowText";
import ModelState from "../shared/Helpers/ModelState";

/** A customizable input element with floating label.
 * @param {Array} validationOptions - An array of validationOptions, each having either pattern or validate function and errorMessage. Do not include required here, required is a separate prop. 
 * @param {boolean} required - Represents whether the input is required or not, displaying a generic error message if it is and no input is provided.
 * @returns 
 */
function FloatedInput({value="", setValue, valid=true, setValid, name, validationOptions=[], label="", className="", required=false, requiredErrorMessage=label.replace(/\.*$/, '') + " is required.",
    id="", type="text", placeholder=""}) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [isDisplayingError, setIsDisplayingError] = useState(false); // after first blur this is set to true and stays true

    useEffect(() => {
        if (name) {
          ModelState.register(name, (errorMsg) => {
            setErrorMessage(errorMsg);
            setIsDisplayingError(true);
            setValid(false);
          }, () => {
            setErrorMessage("");
            setIsDisplayingError(false);
          })
        }
    
        return () => ModelState.unRegister(name);
    }, [name, setValid]); // on mount

    const validate = useCallback((inputValue) => {
        let validationResult = true;

        if (required && inputValue.trim().length === 0) {
            validationResult = false;
            setErrorMessage(requiredErrorMessage);
        }
        if (validationResult && inputValue.trim().length) {
            for (const validationOption of validationOptions) {

                const isValid = validationOption.pattern ? validationOption.pattern.test(inputValue) : validationOption.validate(inputValue);
                if (!isValid) {
                    validationResult = false;
                    setErrorMessage(validationOption.errorMessage);
    
                    break;
                }
            }
        }

        setValid(validationResult);
    }, [required, setValid, validationOptions, requiredErrorMessage]);

    useEffect(() => {
        validate(value);
    }, [value, required, validationOptions, validate]);

    return (
        <div className="form-floating mt-3">
            <input type={type} className={"form-control " + className + ((isDisplayingError && !valid) ? " invalid-input" : "")} placeholder={placeholder} id={id}
            required={required}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={(_) => setIsDisplayingError(true)}
            onKeyUp={(e) => {
                if (e.key === "Enter") 
                    setIsDisplayingError(true)
                }
            }
            />
            <label htmlFor={id}><RollingOverflowText text={(isDisplayingError && !valid) ? errorMessage : label}></RollingOverflowText></label>
        </div>
    );
}

export default memo(FloatedInput);