/**
 * Calculates and sets the --browserScrollbarSize css property to the html element. This is the size of the default scrollbar, unaffected by any css styles.
 * The size is useful to prevent layout shift when to scrollbar appears. If the size is know, scrollbar-gutter can be used to add this size to the left side of an element and,
 * knowing the size of the scrollbar it can now be subtracted from the left margin, to make the scrollbar merely appear in the element's margin without any layout shift.
 * @returns {void}
 */
export function calculateAndSetBrowserScrollbarSizeToRootCssVariable() {
    if (!getComputedStyle(document.documentElement).getPropertyValue("--browserScrollbarSize")) {
        // Add temporary box to wrapper
        let scrollbox = document.createElement('div');

        // Make box scrollable
        scrollbox.style.overflow = 'scroll';

        // Append box to document
        document.body.appendChild(scrollbox);

        // Measure inner width of box
        const scrollBarWidth = scrollbox.offsetWidth - scrollbox.clientWidth;

        // Remove box
        document.body.removeChild(scrollbox);

        document.documentElement.style.setProperty('--browserScrollbarSize', scrollBarWidth);
        document.documentElement.style.setProperty('--isBrowserScrollbarSizeZero', scrollBarWidth === 0 ? 1 : 0);
    }
}

export function getFileExtension(file) {
    return file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) || file.name;
}