import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/CloseOutlined.js';

const SwitchButton = ({visible, onHide, children }) => {

    return (
        <Modal
            show={visible}
            onHide={onHide}
            centered
            className='mediaModal'
        >
            <button type="button" className='btn closeBtn position-absolute top-0 end-0' aria-label='close' onClick={() => onHide()}>
                <CloseIcon/>
            </button>
            {children}
        </Modal>
    );
};

export default SwitchButton;