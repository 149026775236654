import React, { useState, useEffect, useRef, useMemo, useCallback, memo } from "react";
import Select from 'react-select'
import { getCountries, getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input/input'
import { AsYouType } from 'libphonenumber-js'
import { isValidPhoneNumber } from 'react-phone-number-input/max';
import flags from 'react-phone-number-input/flags'
import en from 'react-phone-number-input/locale/en'
import { ReactSelectDropdownIndicator } from "../shared/Constants";
import RollingOverflowText from "./RollingOverflowText";
import ModelState from "../shared/Helpers/ModelState";

const selectStyles = {
    control: (base, { isFocused }) => ({
        ...base,
        cursor: 'text',
        backgroundColor: '#101010',
        borderTopLeftRadius: '7px',
        borderTopRightRadius: '7px',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        border: 'none',
        boxShadow: "none",
        display: "flex",
        flexDirection: "row-reverse",
        paddingTop: "2px",
        paddingBottom: "2px",
        marginRight: "calc(1rem + 10px)",
        marginLeft: "1rem",
        borderBottom: "1px solid #7f7f7f",
        ":hover": {
            borderBottom: "1px solid #7f7f7f",
        }
    }),
    container: (base) => ({
        ...base,
        borderTopLeftRadius: '7px',
        borderTopRightRadius: '7px',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        backgroundColor: "#101010",
        width: "calc(100% - 58px)",
    }),
    option: (base, { isFocused, isSelected }) => ({
        ...base,
        color: '#ffffff',
        cursor: 'pointer',
        backgroundColor: isSelected ? '#282828!important' : isFocused ? '#282828!important' : '#101010!important',
        paddingLeft: "1rem",
        paddingRight: "1rem"
    }),
    menu: (base) => ({
        ...base,
        zIndex: 3,
        margin: 0,
        backgroundColor: "#101010",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: '7px',
        borderBottomRightRadius: '7px',
        overflow: "hidden",
    }),
    menuList: (base) => ({
        ...base,
        padding: 0,
        marginRight: "10px"
    }),
    input: (base) => ({
        ...base,
        color: "white"
    }),
    valueContainer: (base) => ({
        ...base,
        padding: 0
    })
};

const DropdownIndicator = () => ( // search icon used as dropdown indicator
    <div style={{ color: "#ffffff", height: 24, width: 32 }}>
        <svg 
            width="20"
            height="18"
            viewBox="0 0 24 24"
            focusable="false"
            role="presentation"
            style={{verticalAlign: "sub"}}
            >
            <path
                d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    </div>
);

function formatOptionLabel({value, label, code}) {
    const Flag = flags[value];
    return (
        <div style={{ display: "flex", alignItems:"center" }}>
            <Flag width="25px" className="countryFlagIcon" style={{minWidth: "25px", marginTop: "1px"}}/>&nbsp;&nbsp;
            <span>{label}</span>
            <span>
                &nbsp;{`(${code})`}
            </span>
        </div>
    );
}

function TwinzoPhoneNumberSelect({id="", required, value="", validationOptions=[], setValue, className="", isPhoneNumberValid, setIsPhoneNumberValid, name, defaultCountry, label="Your phone number"}) {
    const inputRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const selectCountryOptions = useMemo(() => getCountries().map(countryCode => {
        const countryName = en[countryCode];
        const callingCode = '+' + getCountryCallingCode(countryCode);
        
        return {
            value: countryCode,
            label: countryName,
            code: callingCode
        };
    }), []);
    let [selectedCountry, setSelectedCountry] = useState(selectCountryOptions.find(c => c.value === defaultCountry));
    const formControlRef = useRef(null);

    const [inputValue, setInputValue] = useState("");

    const previouslyFocusedElement = useRef(document.activeElement);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isDisplayingError, setIsDisplayingError] = useState(false); // after first blur this is set to true and stays true
    
    useEffect(() => {
        setValue(selectedCountry?.code + inputValue.replace(/\s/g, ""));
    }, [inputValue, selectedCountry, setValue]);

    const dropDownRef = useRef(null);
    // Catch if user clicks outside, if so, close select
    useEffect(() => {
      function handleClickOutside(event) {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);

      function setDisplayingErrorToTrueOnBlur(event) {
        if (dropDownRef.current.contains(previouslyFocusedElement.current)) {
            if (!dropDownRef.current.contains(event.target) && document.contains(event.target)/*Select options disappear immediately, so if click on something not in dom its select option*/)
                setIsDisplayingError(true)
        }

        previouslyFocusedElement.current = event.target;
      }
      document.addEventListener("click", setDisplayingErrorToTrueOnBlur);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener("click", setDisplayingErrorToTrueOnBlur);
      };
    }, []);

    function formatAndValidatePhoneNumber(number, selectedCountry) {
        const parsedNumber = parsePhoneNumber((number.includes('+') ? "" : selectedCountry?.code) + number, selectedCountry ? selectedCountry.value : defaultCountry);

        if (parsedNumber?.country) { // If country is determined from the number, select it
            const countryToSelect = selectCountryOptions.find((c) => c.value === parsedNumber.country);
            setSelectedCountry(countryToSelect);
            selectedCountry = countryToSelect; // setting selectedCountry here is important for the code that comes after this if block, since it uses selectedCountry
        }

        let numberToDisplay = parsedNumber ? new AsYouType().input(parsedNumber.formatInternational()) : number; // Format the international number
        setIsPhoneNumberValid(isValidPhoneNumber(numberToDisplay));
        if (parsedNumber && selectedCountry) // If country is selected(and therefore its calling code is displayed before the number), take out the country code from the formatted number
            numberToDisplay = numberToDisplay.substring(numberToDisplay.indexOf(selectedCountry.code) + selectedCountry.code.length + 1)
        setInputValue(numberToDisplay);
        setErrorMessage(label + " is invalid.");

        return numberToDisplay;
    };

    useEffect(() => {
        if (name) {
          ModelState.register(name, (errorMsg) => {
            setErrorMessage(errorMsg);
            setIsDisplayingError(true);
            setIsPhoneNumberValid(false);
          }, () => {
            setErrorMessage("");
            setIsDisplayingError(false);
          })
        }
    
        return () => ModelState.unRegister(name);
    }, [name, setIsPhoneNumberValid]); // on mount

    const validate = useCallback((inputValue) => {
        let validationResult = true;
        if (required && inputValue.trim().length === 0) {
            validationResult = false;
            setErrorMessage(label + " is required.");
            setIsPhoneNumberValid(validationResult);
        }
        if (validationResult && inputValue.trim().length) {
            for (const validationOption of validationOptions) {

                const isValid = validationOption.pattern ? validationOption.pattern.test(inputValue) : validationOption.validate(inputValue);
                if (!isValid) {
                    validationResult = false;
                    setErrorMessage(validationOption.errorMessage);
                    setIsPhoneNumberValid(validationResult)
    
                    break;
                }
            }
        }
    }, [validationOptions, required, label, setIsPhoneNumberValid]);
    useEffect(() => {
        validate(inputValue);
    }, [inputValue, validate])
    
    const Flag = selectedCountry ? flags[selectedCountry.value] : undefined;
    return (
        <div className={className + " form-floating phoneNumberInputWrappper" + ((!isPhoneNumberValid && isDisplayingError) ? " invalid-input" : "")} ref={dropDownRef}
        onClick={(event) =>{ if (event.target === dropDownRef.current || event.target === formControlRef.current) inputRef.current.focus();}}>
            <div className={"form-control"} ref={formControlRef}>
                <span className="phoneNumberCountryCodeIndicator">{Flag && (<><Flag width="25px" className="countryFlagIcon" style={{marginBottom: "1px"}}/>&nbsp;</>)} {selectedCountry ? (
                        <>
                            {selectedCountry.code}&nbsp;
                            <span className="textSeparator"></span>&nbsp;
                        </>
                    ) : null}
                </span>
                <input type="tel" placeholder="" id={id} required={required} value={inputValue} onChange={(event) => {
                    let caretStart = event.target.selectionStart;
                    let caretEnd = event.target.selectionEnd;

                    let number = event.target.value;
                    const previousDisplayedNumber = inputValue;

                    const numberToDisplay = formatAndValidatePhoneNumber(number, selectedCountry);
                
                    setTimeout(() => { // This is to persist text cursor position, because setInputValue(formattedNumber); would set it to the end
                        if (numberToDisplay.length > number.length) {
                            caretStart += 1;
                            caretEnd += 1;
                        }

                        event.target.setSelectionRange(caretStart, caretEnd);

                        if (numberToDisplay.includes(" ") && !previousDisplayedNumber.includes(" ")) {
                            event.target.setSelectionRange(10000, 10000);
                        }
                    });
                }} onKeyDown={(event) => {
                    if (event.key === "+" && (selectedCountry || inputValue.includes("+")))
                        event.preventDefault();
                    if (event.key === "Backspace" && !inputValue && selectedCountry) {
                        event.preventDefault();
                        setInputValue(selectedCountry.code);
                        setSelectedCountry(null);
                    } //else if (event.key === "Backspace" && inputValue === "+" && !selectedCountry)
                        //event.preventDefault();
                }} ref={inputRef} size={16} maxLength={16}/>
            </div>
            <label htmlFor={id}><RollingOverflowText text={(isDisplayingError && !isPhoneNumberValid) ? errorMessage : label}></RollingOverflowText></label>

            <button type="button" className="btn btn-primary selectorIndicator" onClick={() => {setIsOpen((prev) => !prev)}} title="Open Selector"><ReactSelectDropdownIndicator/></button>
            {isOpen && <Select
                className="z-3"
                autoFocus
                backspaceRemovesValue={false}
                components={{ DropdownIndicator, IndicatorSeparator: null }}
                formatOptionLabel={formatOptionLabel}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                menuIsOpen
                onChange={(newValue) => {
                    setSelectedCountry(newValue);
                    setIsOpen(false);
                    if (inputValue.includes("+")) {
                        setInputValue("");
                    } else {
                        formatAndValidatePhoneNumber(inputValue, newValue);
                    }
                    validate(inputValue);
                    inputRef.current.focus();
                }}
                options={selectCountryOptions}
                placeholder="Search for Country"
                styles={selectStyles}
                tabSelectsValue={false}
                value={selectedCountry}
            />}
        </div>
    )
};

export default memo(TwinzoPhoneNumberSelect);
