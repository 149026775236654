// Function to initialize Google Tag Manager
export const initializeGoogleTagManager = (gtmId) => {
  if (!gtmId) {
    gtmId = process.env.REACT_APP_GTM_ID;
  }

  // Remove any existing GTM script if already added
  const existingScript = document.querySelector(`script[src*="googletagmanager.com/gtm.js?id="]`);
  if (existingScript) {
    existingScript.remove();
  }

  const existingNoscript = document.querySelector("noscript iframe[src*='googletagmanager.com/ns.html?id=']");
  if (existingNoscript && existingNoscript.parentElement) {
    existingNoscript.parentElement.removeChild(existingNoscript);
  }

  // Initialize data layer
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js",
  });

  // Inject the GTM script
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
  document.head.appendChild(script);

  // Inject the noscript tag
  const noscript = document.createElement("noscript");
  const iframe = document.createElement("iframe");
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
  iframe.style.display = "none";
  iframe.style.visibility = "hidden";
  iframe.width = "0";
  iframe.height = "0";
  noscript.appendChild(iframe);
  document.body.insertBefore(noscript, document.body.firstChild);
};
