import React, { useState, useEffect } from "react";
import RegistrationForm from "../RegistrationForm.js";
import { useLoading } from "components/LoadingProvider.js";
import { isEmail } from 'react-multi-email';
import FloatedInput from "components/FloatedInput.js";
import ModelState from "shared/Helpers/ModelState.js";
import ReCaptcha from "components/ReCaptcha.js";

const emailAddressValidationOptions = [
  { validate: isEmail, errorMessage: "This email address is invalid." },
  { validate: email => email.length <= 250, errorMessage: "This email address is longer than maximum allowed characters of 250."}
];

const RegistrationUser = ({ onNext, onCancel, showCancel, showPrevious, showNext, showSkip, nextButtonText }) => {
  const { setLoading, setTextHeader, setTextMessage, setProgress } = useLoading();
  const [isFormValid, setIsFormValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const [name, setName] = useState("");
  const [isNameValid, setIsNameValid] = useState(false);
  const [surname, setSurname] = useState("");
  const [isSurnameValid, setIsSurnameValid] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState(false);

  useEffect(() => {
    setIsFormValid(isNameValid && isEmailValid && isSurnameValid);

    setErrorMessage(null);
  }, [isEmailValid, isNameValid, isSurnameValid]);

  const handleNext = async (e) => {
    // set loading text
    setTextHeader("Creating a Twinzo user account...");
    setTextMessage("Twinzo is working...");

    setLoading(true);
    setProgress(0);

    try {
      const response = await fetch("/Registration/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          surname: surname,
          email: email,
        }),
      });

      if (response.ok) {
        setErrorMessage(null);
        onNext();
      } else {
        const problemDetails = await response.json();
        if (problemDetails.Errors)
          ModelState.validate(problemDetails.Errors);
        else 
          setErrorMessage("Something went wrong.");
      }
    } catch (error) {
      setErrorMessage("Something went wrong.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = (_) => {
    setName("");
    setSurname("");
    setEmail("");
    setIsTermsAndConditionsChecked(false);
    ModelState.resetValidationState();
    onCancel();
  }

  return (
    <RegistrationForm
      onNext={handleNext}
      onSkip={onNext}
      onCancel={handleCancel}
      isInputValid={isFormValid && isCaptchaVerified && isTermsAndConditionsChecked}
      showCancel={showCancel}
      showPrevious={showPrevious}
      showNext={showNext}
      showSkip={showSkip}
      nextButtonText={nextButtonText}
    >
      <>
        <h4>La Ruche twin registration</h4>
        <div className="dialogContent">
          <p>
            Please enter your name, surname and email address and you will receive an invitation email.
          </p>
          <div className="form-group">
            <FloatedInput type="text" id="LaRucheName" required value={name} setValue={setName} valid={isNameValid} setValid={setIsNameValid} name="Name" label="Your name"/>
            <FloatedInput type="text" id="LaRucheSurname" className="mt-3" required value={surname} setValue={setSurname} valid={isSurnameValid} setValid={setIsSurnameValid} name="Surname" label="Your surname"/>
            <FloatedInput type="email" id="LaRucheEmail" className="mt-3" required value={email} setValue={setEmail} valid={isEmailValid} setValid={setIsEmailValid}
            name="Email"
            validationOptions={emailAddressValidationOptions} label="Your email address"/>
            <div className="form-check mt-3">
                <input className="form-check-input" type="checkbox" id="LaRucheTermAndConditionsCheckbox" checked={isTermsAndConditionsChecked}
                onChange={(_) => setIsTermsAndConditionsChecked((prev) => !prev)}
                />
                <label className="form-check-label" htmlFor="LaRucheTermAndConditionsCheckbox">
                  I agree with <a className="d-inline-block text-decoration-none" href="https://partner.twinzo.eu/terms" target="_blank" rel="noreferrer">Terms and Conditions</a>
                </label>
            </div>
          </div>
          {errorMessage && <p className={"error-message mt-3"}>{errorMessage}</p>}
          <ReCaptcha isCaptchaVerified={isCaptchaVerified} setIsCaptchaVerified={setIsCaptchaVerified}/>
        </div>
      </>
    </RegistrationForm>
  );
};

export default RegistrationUser;
