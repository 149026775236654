import React, { useRef, useEffect } from "react";

const RegistrationForm = ({
  onNext,
  onCancel,
  onSkip,
  onPrevious,
  showCancel,
  showPrevious,
  showNext,
  showSkip,
  children,
  isInputValid,
  nextButtonText = "Next",
}) => {
  const formContainerRef = useRef();

  useEffect(() => {
    // formContainer has overflow:hidden so that slide in animation overflow is hidden. but, Select elements open popups that go outside the element,
    // user generally clicks on them after the animation is over, so after it is over make overflow visible to show full select element
    // it has to be done this way, because css does not support just setting overflow-x: hidden and overflow-y: visible, in that case both will be hidden.
    const animationEndEvent = formContainerRef.current.addEventListener("animationend", () => {formContainerRef.current.classList.toggle("overflow-visible");});
    const formContainer = formContainerRef.current;
    return () => {
      formContainer.removeEventListener("animationend", animationEndEvent);
    }
  }, []); 

  // if the environment variable REACT_APP_ENV is set to "Release", forceShowSkip will be false, otherwise it will be true
  // this will be used to determine whether to force show the Skip button or not
  const forceShowSkip = process.env.REACT_APP_ENV.trim() !== "Release";

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Call onNext after handling the form submission
    // onNext();
  };

  return (
    <form className="form-container" onSubmit={handleSubmit} ref={formContainerRef}>
      <div className="slide-in">
        {/* Input fields */}
        {children}
      </div>
      {/* Buttons */}
      <div className="button-group d-grid gap-3 p-3 d-sm-flex">
        {showCancel && (
          <button
            className="btn btn-secondary w-100"
            type="reset"
            onClick={onCancel}
            id="RegistrationCancel"
          >
            Cancel
          </button>
        )}
        {showPrevious && (
          <button className="btn btn-secondary w-100" onClick={onPrevious}>
            Previous
          </button>
        )}
        {(forceShowSkip || showSkip) && (
          <button
            className="btn btn-secondary w-100"
            type="button"
            onClick={onSkip}
          >
            Skip
          </button>
        )}
        {showNext && (
          <button
            className="btn btn-primary w-100"
            id="RegistrationNext"
            type="submit"
            onClick={onNext}
            disabled={!isInputValid}
          >
            {nextButtonText}
          </button>
        )}
      </div>
    </form>
  );
};

export default RegistrationForm;