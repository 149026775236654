import React, { useState, useEffect, useMemo } from "react";
import RegistrationForm from "../RegistrationForm.js";
import { useLoading } from "components/LoadingProvider.js";
import countryList from 'react-select-country-list'
import TwinzoPhoneNumberSelect from "components/TwinzoPhoneNumberSelect.js";
import { isEmail } from 'react-multi-email';
import TwinzoCountrySelect from "components/TwinzoCountrySelect.js";
import FloatedInput from "components/FloatedInput.js";
import { GENERIC_EMAIL_ADDRESSES } from "shared/Constants.js";
import ModelState from "shared/Helpers/ModelState.js";
import ReCaptcha from "components/ReCaptcha.js";

const emailAddressValidationOptions = [
  { validate: isEmail, errorMessage: "This email address is invalid." }, 
  { validate: (inputValue) => {
      for (const genericEmailDomain of GENERIC_EMAIL_ADDRESSES) {
        if (inputValue.includes(genericEmailDomain))
          return false;
      }
      
      return true;
    }, errorMessage: "Unsupported email address domain. Please use your business email address." }
];

const RegistrationEmailValidation = ({ onNext, onCancel, showCancel, showPrevious, showNext, showSkip, nextButtonText, defaultCountry }) => {
  const { setLoading, setTextHeader, setTextMessage, setProgress } = useLoading();
  const [isFormValid, setIsFormValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const [name, setName] = useState("");
  const [isNameValid, setIsNameValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [isCompanyNameValid, setIsCompanyNameValid] = useState(false);
  const countryOptions = useMemo(() => countryList().getData(), [])
  const [country, setCountry] = useState(countryOptions.find(c => c.value === defaultCountry));
  const [isCountryValid, setIsCountryValid] = useState(false);

  useEffect(() => {
    setIsFormValid(isNameValid && isPhoneNumberValid && isEmailValid && isCompanyNameValid && isCountryValid);

    setErrorMessage(null);
  }, [isEmailValid, isNameValid, isPhoneNumberValid, isCompanyNameValid, isCountryValid]);

  const handleNext = async (e) => {
    // set loading text
    setTextHeader("Sending you a verification email...");
    setTextMessage("twinzo is working...");

    setLoading(true);
    setProgress(0);

    try {
      const response = await fetch(`/Registration/client/emailValidation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          phoneNumber: phoneNumber,
          emailAddress: email,
          companyName: companyName,
          country: country.value,
        }),
      });

      if (response.ok) {
        setErrorMessage(null);
        onNext();
      } else {
        const problemDetails = await response.json();
        if (problemDetails.Errors)
          ModelState.validate(problemDetails.Errors);
        else 
          setErrorMessage("Something went wrong.");
      }
    } catch (error) {
      setErrorMessage("Something went wrong.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <RegistrationForm
      onNext={handleNext}
      onSkip={onNext}
      onCancel={onCancel}
      isInputValid={isFormValid && isCaptchaVerified}
      showCancel={showCancel}
      showPrevious={showPrevious}
      showNext={showNext}
      showSkip={showSkip}
      nextButtonText={nextButtonText}
    >
      <>
        <h4>Start your registration process</h4>
        <div className="dialogContent">
          <p>
            We'll send a confirmation link to ensure your email is valid and ready for hassle-free onboarding.
          </p>
          <div className="form-group">
            {/* Client Login name input */}
            <FloatedInput type="text" id="EmailValidationName" required value={name} setValue={setName} valid={isNameValid} setValid={setIsNameValid} label="Your name"/>
            <TwinzoPhoneNumberSelect id="EmailValidationNumber" required={true} value={phoneNumber} setValue={setPhoneNumber} className={"mt-3 z-3"}
            isPhoneNumberValid={isPhoneNumberValid} setIsPhoneNumberValid={setIsPhoneNumberValid} defaultCountry={defaultCountry}/>
            <FloatedInput type="email" id="EmailValidationEmail" className="mt-3" required value={email} setValue={setEmail} valid={isEmailValid} setValid={setIsEmailValid}
            name="EmailAddress"
            validationOptions={emailAddressValidationOptions} label="Your business email"/>
            <FloatedInput type="text" id="EmailValidationCompany" className="mt-3" required value={companyName} setValue={setCompanyName} valid={isCompanyNameValid} setValid={setIsCompanyNameValid} label="Company name"/>
            <TwinzoCountrySelect id="EmailValidationCountry" className={"form-floating w-100 mt-3"} required={true} label="Country" valid={isCountryValid} setValid={setIsCountryValid}
             value={country} setValue={setCountry} menuPlacement="top" />
          </div>
          {errorMessage && <p className={"error-message mt-3"}>{errorMessage}</p>}
          <ReCaptcha isCaptchaVerified={isCaptchaVerified} setIsCaptchaVerified={setIsCaptchaVerified}/>
        </div>
      </>
    </RegistrationForm>
  );
};

export default RegistrationEmailValidation;
