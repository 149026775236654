import React, { useState, useEffect } from "react";
import RegistrationForm from "../RegistrationForm.js";
import { useLoading } from "components/LoadingProvider.js";
import { useTimezoneSelect, allTimezones } from "react-timezone-select"
import FloatedInput from "components/FloatedInput.js";
import FloatedSelect from "components/FloatedSelect.js";

const labelStyle = "original";
const branchNameValidationOptions = [
  { pattern: /^[a-zA-Z0-9]{3,50}$/, errorMessage: "Name of the digital twin must consist of only letters and numbers, with length between 3 and 50." }
];

const RegistrationBranchForm = ({
  onNext,
  onCancel,
  registrationEmailValidationMetadata,
  clientName,
  branchName,
  setBranchName,
  showCancel, showPrevious, showNext, showSkip, nextButtonText
}) => {
  const { setLoading, setTextHeader, setTextMessage } = useLoading();
  const [errorMessage, setErrorMessage] = useState("");
  const [isInputValid, setIsInputValid] = useState(false);
  const [isBranchNameValid, setIsBranchNameValid] = useState(false);
  const { options, parseTimezone } = useTimezoneSelect({ labelStyle, allTimezones })
  const [selectedTimezone, setSelectedTimezone] = useState(
    parseTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  );
  const [isSelectedTimezoneValid, setIsSelectedTimezoneValid] = useState(false);

  useEffect(() => {
    // if textbox is filled correctly and setSelectedTimezone is defined
    setIsInputValid(isBranchNameValid && isSelectedTimezoneValid);
  }, [isBranchNameValid, isSelectedTimezoneValid]);

  const handleNext = async () => {
    // set loading text
    setTextHeader("Creating digital twin space...");
    setTextMessage(
      "Setting up your digital twin and configuring spatial datasets..."
    );

    setLoading(true);

    // Send client registration request to the controller on the server
    fetch("/Registration/client", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ClientName: clientName,
        BranchName: branchName,
        Timezone: selectedTimezone.value,
        RegistrationValidationToken: registrationEmailValidationMetadata.token
      }),
    })
      .then((response) => {
        if (response.ok) {
          onNext();
        } else {
          setErrorMessage("Client registration failed.");
        }
        return response.json();
      })
      .catch((error) => {
        setErrorMessage("Something went wrong.");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <RegistrationForm
      onNext={handleNext}
      onSkip={onNext}
      onCancel={onCancel}
      isInputValid={isInputValid}
      showCancel={showCancel}
      showPrevious={showPrevious}
      showNext={showNext}
      showSkip={showSkip}
      nextButtonText={nextButtonText}
    >
      <>
        <h4>Type in the name of your digital twin</h4>
        <div className="dialogContent">
          <p>
            Let's give your digital twin a name! Please enter a name for your
            digital twin that will help you easily identify it in the future.
            Also, please provide the timezone for your digital twin so that we
            can ensure accurate data tracking.
          </p>
          <div className="form-group">
            <FloatedInput type="text" id="BranchName" className="mt-3" required value={branchName} setValue={setBranchName} valid={isBranchNameValid} setValid={setIsBranchNameValid}
            validationOptions={branchNameValidationOptions}
            label="Name of the digital twin..."/>
            <FloatedSelect id="Timezone" className={"form-floating w-100 mt-3"} required options={options} value={selectedTimezone} onChange={setSelectedTimezone} menuPlacement="top"
            valid={isSelectedTimezoneValid} setValid={setIsSelectedTimezoneValid}
            label="Timezone"/>
          </div>
          {errorMessage && <p className={"error-message mt-3"}>{errorMessage}</p>}
        </div>
      </>
    </RegistrationForm>
  );
};

export default RegistrationBranchForm;
