import { React, useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { DialogLayout } from "./components/layouts/DialogLayout.js";
import { LoadingProvider } from "./components/LoadingProvider.js";
import RegistrationClientDialog from "./views/registration/client/RegistrationClientDialog.js";
import CookieConsent from "./components/CookieConsentPopup.js";
import RegistrationLaRucheUserDialog from "views/registration/user/RegistrationLaRucheUserDialog.js";
import { useLocation } from 'react-router-dom';
import { initializeGoogleTagManager } from 'shared/Helpers/GoogleTagManager.js';

const App = () => {
  const [showCookieConsent, setShowCookieConsent] = useState(true);

  const location = useLocation();
  // Use different GtmId at different routes
  const getGtmIdForRoute = (pathname) => {
    if (pathname === '/d6599574-2ecd-48ad-a680-cfe23df72c98') {
      return process.env.REACT_APP_GTM_ID_LERUCHE; // GTM ID for LaRuche mass user registration page
    } else {
      return process.env.REACT_APP_GTM_ID; // Default GTM ID
    }
  };

  useEffect(() => {
    initializeGoogleTagManager(getGtmIdForRoute(location.pathname));
  }, [location.pathname]);

  return (
    <LoadingProvider>
      <Routes>
        <Route
          path="/"
          element={
            <DialogLayout>
              <RegistrationClientDialog />
            </DialogLayout>
          }
        ></Route>
          <Route
            path="/d6599574-2ecd-48ad-a680-cfe23df72c98" // LaRuche mass user registration
            element={
              <DialogLayout>
                <RegistrationLaRucheUserDialog />
              </DialogLayout>
            }
          ></Route>
      </Routes>
      {showCookieConsent && (
        <CookieConsent onAccept={() => setShowCookieConsent(false)} />
      )}
    </LoadingProvider>
  );
};

export default App;
