/**
 * Mimicks .Net's ModelState. After receiving a 400 - 500 response from the server, pass its Errors property to the validate function.
 * All components that support validation register themselves using "name" prop, and this name is then used to set error from Errors response property on that component. 
 */
class ModelState {
    static inputComponentsToValidate = {};

    /**
     * Registers a component for validation.
     * @param {string} name - The name of the component to register, it should match the name of property that this component's value is passed to in Fetch requests.
     * @param {function(errorMessage) : void} setInvalid - Called after an error is parsed for this component from HttpResponse. The error message is passed as an argument.
     * @param {function() : void} resetState - Called when the component's validation state is to be reset.
     * @returns {void}
     */
    static register(name, setInvalid, resetState) {
      this.inputComponentsToValidate[name] = { setInvalid, resetState };
    }

    /**
     * UnRegisters a component for validation.
     * @param {string} name - The name of the component to unRegister, it should match the name that the component used to register itself.
     * @returns {void}
     */
    static unRegister(name) {
        this.inputComponentsToValidate[name] = undefined;
    }

    /**
     * Parses .Net's modelStateErrors received from HttpResponse, it sends the first error for each property to its corresponding component identified by the name of the property.
     * @param {string} modelStateErrors - An object containing properties, where the name of the property matches the name of the component and the value of it is an array of errors.
     * @returns {void}
     */
    static validate(modelStateErrors) {
        if (modelStateErrors) {
            for (const [name, errors] of Object.entries(modelStateErrors)) {
                const setInvalid = this.inputComponentsToValidate[name]?.setInvalid;
                if (setInvalid) {
                    setInvalid(errors[0]);
                }
            }
        }
    }

    /**
     * Resets validation state. It is useful to call when you for example want to clear all form fields, including their validaiton state.
     * @returns {void}
     */
    static resetValidationState() {
        for (const componentCallbacks of Object.values(this.inputComponentsToValidate)) {
            componentCallbacks?.resetState();
        }
    }
}

export default ModelState;