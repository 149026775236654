import React, { useState } from 'react';
import { isEmail } from 'react-multi-email';

import RegistrationForm from '../RegistrationForm.js';
import { useLoading } from 'components/LoadingProvider.js';
import TagBox from 'components/TagBox.js';

// Email addresses are sent an invitation to become administrators of client workspace
const RegistrationUserForm = ({ onNext, onCancel, registrationEmailValidationMetadata, showCancel, showPrevious, showNext, showSkip, nextButtonText }) => {
  const { setLoading, setTextHeader, setTextMessage } = useLoading();
  const [errorMessage, setErrorMessage] = useState('');
  const [emails, setEmails] = useState(registrationEmailValidationMetadata ? [{value: registrationEmailValidationMetadata.emailAddress, notRemovable: true}] : []);
  const [areEmailsValid, setAreEmailsValid] = useState(registrationEmailValidationMetadata ? true : false);

  const handleNext = async () => {
    if (areEmailsValid) {
      setTextHeader("Inviting users...")
      setTextMessage("Sending invitation emails and assigning permissions...");

      setLoading(true);
      fetch('/Registration/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({emails: emails.map(email => email.value), RegistrationValidationToken: registrationEmailValidationMetadata.token}),
      })
        .then(response => {
          if (response.ok) {
            onNext();
          } else {
            setErrorMessage('Something went wrong. Please press "Cancel" and try again later.');
            setAreEmailsValid(false);
          }
          return response.json();
        })
        .catch(error => {
          setErrorMessage('Something went wrong. Please press "Cancel" and try again later.');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <RegistrationForm
      onNext={handleNext}
      onSkip={onNext}
      onCancel={onCancel}
      showCancel={showCancel}
      isInputValid={areEmailsValid}
      showPrevious={showPrevious}
      showNext={showNext}
      showSkip={showSkip}
      nextButtonText={nextButtonText}
    >
      <>
        <h4>Invite your colleagues</h4>
        <div className="dialogContent">
          <p>
            Great job! Now it's time to invite administrators to your workspace.
            Please enter the email addresses of the people you want to invite,
            including yourself, and they will receive an invitation to become administrators.
            The maximum number of invited users is 5.
          </p>
          <p className="mt-3">
            Insert email addresses
          </p>
          <div className="form-group">
            <TagBox id='EmailAddress' label='Email addresses' tags={emails} setTags={setEmails} inputType="email" required maxTagCount={5}
            valid={areEmailsValid} setValid={setAreEmailsValid} tagValidation={isEmail}/>
          </div>
          {errorMessage && <p className={"error-message mt-3"}>{errorMessage}</p>}
        </div>
      </>
    </RegistrationForm>
  );
};

export default RegistrationUserForm;