import React from 'react';
import logoImage from '../../content/images/twinzo-horizontal-light.png';
//import InfoIcon from '@mui/icons-material/Info';

// DialogLayout is a functional component that takes children as a prop and returns an HTML element
const DialogLayout = ({ children }) => {
    // Return the HTML elements that make up the DialogLayout component
    return (
        <div className='d-flex justify-content-center'>
            <div className='mainContainer d-inline-flex flex-column justify-content-center'>
                <header className="d-flex flex-column justify-content-center align-items-center">
                    <div className="mb-3">
                        {/* Image */}
                        <img src={logoImage} alt="twinzo - Digital Twin platform" />
                    </div>
{/*                     <div className="col-12 alert alert-warning alert-dismissible fade show" role="alert">
                        <div className="alert-content justify-content-between">
                            <p className='flex-grow-1 text-center'>
                                Self onboarding is currently in Beta.<br />
                                You can expect some things to break.
                            </p>
                            <div className="alert-icon">
                                <InfoIcon fontSize="large" className="svgNoShadow"/>
                            </div>
                        </div>
                    </div> */}
                </header>
                <main>
                    {children}
                </main>
                <footer className="d-flex justify-content-center align-items-center">
                    {/* Your footer content goes here */}
                </footer>
            </div>
        </div>
    );
};

export { DialogLayout };
