import React, { useState } from "react";

const CookieConsent = ({ onAccept, onCancel }) => {
  const [accepted, setAccepted] = useState(localStorage.getItem("cookieConsentAccepted") ?? false);

  const handleAccept = () => {
    setAccepted(true);
    onAccept();

    // Store the acceptance in local storage
    localStorage.setItem("cookieConsentAccepted", "true");

    // Set session and security cookies
    document.cookie = "session_id=your_session_id; path=/";

    // Set preferences cookies
    document.cookie = "frontend_lang=your_frontend_lang; path=/";
  };

  if (accepted) {
    return null;
  }

  return (
    <div className="consent-container">
      <div className="overlay"></div>
      <div className="cookie-consent-wrapper">
        <div className="dialog">
          <h4>We require some essential cookies for you to continue.</h4>
          <div
            className="dialogContent">
            <p>
              We use these essential cookies to keep track of your progress
              while you set up your twinzo Digital Twin.
              <br />
              We require essential cookies only, no 3rd party sharing.
            </p>
            <ul className="links-list mt-3">
              <li>
                <a href={process.env.REACT_APP_TWINZO_MAIN_DOMAIN.trim() + "cookie-policy"} target="_blank" rel="noreferrer">
                  Our Cookie Policy
                </a>
              </li>
            </ul>
            <div className="button-group d-grid gap-2 d-sm-flex mt-3">
              <a
                href={process.env.REACT_APP_TWINZO_MAIN_DOMAIN.trim()}
                className="btn btn-secondary w-100"
                role="button"
              >
                Cancel
              </a>
              <button className="btn btn-primary w-100" onClick={handleAccept}>
                Accept Essential
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
